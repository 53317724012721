<style lang="scss" scoped>
.eTrip-section-cont {
    background-color: #fff;
    padding-bottom: 40px;
    padding: 0 16px;
}

.calendar_container {
    margin: 20px 0;

    /deep/ {
        .vc-highlight {
            background-color: #0452dc !important;
        }
    }

    .calendar_tools {
        padding: 12px 0;
        display: flex;
        justify-content: flex-end;
    }
}

/deep/ {
    .el-calendar-day {
        height: 34px;
        text-align: center;
        font-size: 14px;
        color: #333;
    }

    thead {
        th {
            padding: 5px 0;
            font-size: 14px;
            color: #333;
        }
    }
}

.form_container {
    margin-top: 20px;

    h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .tips {
        color: #999;
    }
}

.period_head {
    display: flex;
    padding-bottom: 8px;
    justify-content: space-between;
    font-size: 14px;

    .tips {
        color: #999;
    }
}
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont" v-loading="loading">
            <div class="calendar_container">
                <v-calendar ref="calendar" is-expanded :attributes="attributes" @dayclick="onDayClick"></v-calendar>
                <div class="calendar_tools">
                    <el-button size="mini" type="primary" @click="handleCalendar(0)">全选</el-button>
                    <el-button size="mini" type="warning" @click="handleCalendar(1)">清空</el-button>
                </div>
            </div>
            <periods v-model="values" :dataList="list" :options="periodOptions">
                <template v-slot:period="slotProps">
                    {{ slotProps.data.startTime }} - {{ slotProps.data.endTime | formatMinusDate(true) }}
                    <p v-if="!slotProps.data.id||slotProps.data.totalStockNum===-999" style="color: #0452dc">不限</p>
                    <p v-else>
                        已消耗<span style="color: #FF3B30">{{ slotProps.data.usedStockNum }}</span>/总库存<span
                        style="color: #0452dc">{{ slotProps.data.totalStockNum }}</span>
                    </p>
                </template>
            </periods>
            <div class="form_container">
                <h5>设置选中时段：</h5>
                <el-form ref="form" :inline="true" size="small" :model="form" :rules="rules">
                    <el-form-item label="时段库存总数：" prop="totalStockNum">
                        <el-row :gutter="10">
                            <el-col :span="10" v-if="!form.checked">
                                <el-input placeholder="库存"
                                          v-model="form.totalStockNum"
                                          type="number"
                                          min="0"></el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-checkbox v-model="form.checked" @change="handleCheckChange">不限库存</el-checkbox>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <p class="tips">提示：库存总数≥<span style="color:#FF3B30 ">{{ minimum }}</span>已使用库存数。填写的内容将覆盖原有数据
                        </p>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="loading">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-main>

</template>

<script type="text/ecmascript-6">
import periods, {formatMinusDate} from '../../../../components/v-periods/periods-panel.vue'
import ElButton from "../../../../../node_modules/element-ui/packages/button/src/button.vue";
import moment from 'moment'
import {uniqueArray} from '@/common/js/Utils'
import VCalendar from 'v-calendar'
import dayjs from "dayjs";

export default {
    //定义模版数据
    data() {
        return {
            days: [],
            list: [],
            values: [],
            loading: false,
            selectedDate: undefined,
            form: {
                totalStockNum: 0,
                checked: false
            },
            rules: {
                totalStockNum: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value && value !== 0)
                                return callback(new Error('请输入输入库存'));
                            if (value != -999 && value < this.minimum)
                                return callback('填写的库存总数需≥已使用库存数。请核对后再填写。')
                            callback()
                        }, trigger: 'blur'
                    }
                ]
            },
            periodOptions: {
                custom: true,
                customHead: false,
                endTimeMinus: true
            }
        }
    },
    filters: {
        formatMinusDate
    },
    computed: {
        minimum() {
            return Math.max(0, ...this.values.map(({usedStockNum}) => (usedStockNum || 0)).flat())
        },
        attributes() {
            return this.days.map(({date}) => {
                return {
                    highlight: true,
                    dates: date,
                }
            })
        }
    },
    components: {
        ElButton,
        periods,
        VCalendar
    },
    //主件被加载完成
    mounted() {
        if (this.$route.query.date)
            this.days = [{
                id: dayjs(Number(this.$route.query.date)).format('YYYYMMDD'),
                date: dayjs(Number(this.$route.query.date)).toDate()
            }]
        this.getData();
    },
    //定义事件方法
    methods: {
        async getData() {
            if (this.days.length !== 1) {
                this.list = [];
                return
            }
            this.loading = true;
            const ret = await this.http('/galaxyQuoteConfigurationApi/queryQuoteRuleStockInfo', {
                ruleId: this.$route.params.id,
                date: moment(this.days[0].date).format('YYYYMMDD')
            }, 'POST')
            if (ret.success && ret.data) {
                this.list = ret.data;
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        },
        //切换不限库存
        handleCheckChange(val) {
            this.form.totalStockNum = val ? '-999' : 0
        },
        //提交修改
        async handleSubmit() {
            if (this.days.length === 0)
                return this.$message.error('请选择日期')
            if (this.values.length === 0)
                return this.$message.error('请选择时段')
            const valid = await this.$refs['form'].validate()
            if (!valid)
                return;
            this.loading = true;
            const list = this.days.map(({date}) => {
                const ls = this.values.map((item) => ({
                    ...item,
                    totalStockNum: this.form.totalStockNum,
                    ruleId: this.$route.params.id,
                    date: moment(date).format('YYYYMMDD')
                }))
                return ls;
            })
            const ret = await this.http('/galaxyQuoteConfigurationApi/editQuoteRuleStockInfo', list.flat(), 'POST')
            if (ret.success && ret.data) {
                this.$message.success('设置成功！');
                this.values = [];
                this.form = {
                    totalStockNum: 0,
                    checked: false
                }
                this.getData()
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        },
        //日期被选择
        onDayClick(day) {
            const idx = this.days.findIndex(d => d.id === day.id);
            if (idx >= 0) {
                this.days.splice(idx, 1);
            } else {
                this.days.push({
                    id: day.id,
                    date: day.date,
                });
            }
            this.getData();
        },
        //操作日历工具栏
        handleCalendar(index) {
            switch (index) {
                case 0:
                    this.days = uniqueArray([...this.days, this.$refs['calendar'].getPageDays().filter(({inMonth}) => inMonth)], 'id');
                    break;
                case 1:
                    this.days = [];
                    break;
            }
        }
    },
    //监听模版变量
    watch: {
        minimum() {
            if (this.form.totalStockNum < this.minimum)
                this.form.totalStockNum = this.minimum;
        }
    }

}
</script>
