import { render, staticRenderFns } from "./special-time-quotation.vue?vue&type=template&id=165b47d1"
import script from "./special-time-quotation.vue?vue&type=script&lang=js"
export * from "./special-time-quotation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports